import React, { useEffect, useRef } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import birgerPhoto from './birmoe.jpeg'; // Ensure you have this image in your src folder

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const AppContainer = styled.div`
  text-align: center;
  min-height: 100vh;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  color: white;
  font-family: 'Arial', sans-serif;
`;

const Section = styled.section`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  margin-bottom: 40px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Photo = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const Title = styled.h1`
  font-size: 3em;
  margin: 20px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

const Paragraph = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: #ffcc00;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #ff9900;
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const ProjectCard = styled.a`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease, background 0.3s ease;
  text-decoration: none;
  color: white;
  display: block;

  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.3);
  }
`;



const projects = [
  {
    title: "Swedish Chatbot Arena",
    description: "A Swedish version of the LMSYS chatbot arena to compare different LLMs in Swedish.",
    url: "https://chatbotarena.se"
  },
  {
    title: "AI-Powered Therapy",
    description: " A web platform designed to explore how AI can be used to improve mental health.",
    url: "https://birgermoell.github.io/friend/"
  },
  {
    title: "Swedish Medical Benchmark",
    description: "Comparing the quality of LLMs in the healthcare domain.",
    url: "https://github.com/BirgerMoell/swedish-medical-benchmark/"
  },
  {
    title: "AI Lectures",
    description: "Lectures about different aspects of AI.",
    url: "https://docs.google.com/presentation/d/1HzsI0c4VJvKHig-pq8rPL6KAGznYIF5To51Aa9aIy5Y/edit?usp=sharing"
  },
  {
    title: "Custom AI models",
    description: "Training custom AI models including generative models in text, audio and images.",
    url: "https://huggingface.co/birgermoell/"
  },{
    title: "Writing",
    description: "Academic and educational texts about AI",
    url: "https://www.linkedin.com/in/birgermoell/"
  }
];

function App() {
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Section ref={(el) => (sectionRefs.current[0] = el)}>
          <Photo src={birgerPhoto} alt="Birger Moëll" />
          <Title>Birger Moëll</Title>
          <Paragraph>
            Welcome to my personal homepage. I am Birger Moëll, a senior developer and PhD student focused on advancing machine learning for speech and language in clinical diagnostics. My background as a licensed clinical psychologist drives my commitment to using technology to improve lives.
          </Paragraph>
          <Paragraph>
            My work ranges from developing cutting-edge machine learning models to full-stack development, all aimed at creating impactful solutions.
          </Paragraph>
        </Section>

        <Section ref={(el) => (sectionRefs.current[1] = el)}>
          <h2>Projects</h2>
          <ProjectGrid>
            {projects.map((project, index) => (
              <ProjectCard key={index} href={project.url} target="_blank" rel="noopener noreferrer">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </ProjectCard>
            ))}
          </ProjectGrid>
        </Section>

        <Section ref={(el) => (sectionRefs.current[2] = el)}>
          <h2>Contact</h2>
          <Paragraph>Email: birger.moell@gmail.com</Paragraph>
          <Paragraph>
            LinkedIn: <Link href="https://www.linkedin.com/in/birgermoell" target="_blank" rel="noopener noreferrer">linkedin.com/in/birgermoell</Link>
          </Paragraph>
        </Section>
      </AppContainer>
    </>
  );
}

export default App;